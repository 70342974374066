"use client";

import { useState, useEffect } from 'react';

export const usePlatformAndOS = () => {
  const [platform, setPlatform] = useState('');
  const [os, setOS] = useState('');
  const [version, setVersion] = useState('');
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    let detectedOS = '';
    let osVersion = '';
    if (userAgent.includes('windows')) {
      detectedOS = 'Windows';
      const versionMatch = userAgent.match(/windows nt (\d+\.\d+)/);
      if (versionMatch) osVersion = versionMatch[1];
    } else if (userAgent.includes('mac')) {
      detectedOS = 'Mac OS';
      const versionMatch = userAgent.match(/mac os x (\d+[\._]\d+([\._]\d+)?)/);
      if (versionMatch) osVersion = versionMatch[1].replace(/_/g, '.');
    } else if (userAgent.includes('linux')) {
      detectedOS = 'Linux';
    } else {
      detectedOS = 'Unknown';
    }

    setOS(detectedOS);
    setPlatform(navigator.platform);
    setVersion(osVersion);
  }, []);

  return { platform, os, version };
};
