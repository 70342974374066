import { useEffect, useState } from 'react';

export const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState<URLSearchParams | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      setQueryParams(params);
    }
  }, []);

  return queryParams;
};
