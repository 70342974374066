"use client"

import { Dispatch, createContext, useReducer } from "react"
import { IModalActions } from "@/types/modal"
import type { IModalState, IModalAction } from "@/types/modal"

const modalInitState: IModalState = {
    isModalForceClose: false,
    isModalOpen: false,
    modalType: null,
    modalData: {},
  }

export const ModalContext = createContext<{
  modalState: IModalState
  modalDispatch: Dispatch<IModalAction>
}>({
  modalDispatch: () => null,
  modalState: modalInitState,
})

function reducer(state: IModalState, action: IModalAction): IModalState {
  switch (action.type) {
    case IModalActions.OpenModal:
      return { ...state, isModalOpen: true, ...action.data }
    case IModalActions.CloseModal:
      return { isModalOpen: false }
    case IModalActions.ToggleModal:
      if (!state.isModalOpen) {
        return { ...state, isModalOpen: false, ...action.data }
      }
      return { isModalOpen: false }
    case IModalActions.UpdateModal:
      return { ...state, ...action.data }
    default:
      return state
  }
}

export default function ModalContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [modalState, dispatch] = useReducer(reducer, modalInitState)
  return (
    <ModalContext.Provider value={{ modalState, modalDispatch: dispatch }}>
      {children}
    </ModalContext.Provider>
  )
}
