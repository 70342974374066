"use client";
import Link from "next/link";
import React, { FC } from "react";
import clsx from "clsx";
import styles from "./button.module.scss";
import { SvgToUse } from "../Buttons/WarningButton";

// {
//   button: {
//     url?: string;
//     type?: string;
//     target?: string;
//     as?: string;
//   };
//   type?: "normal" | "transparent";
//   icon?: string;
//   color?: string;
//   className?: string;
// }

// To Fix Later
const Button: FC<any> = (props) => {
  const [isHover, setIsHover] = React.useState(false);
  const {
    buttonProps = {},
    type = "link",
    bg = "s",
    icon,
    hoverIcon,
    size,
    color = "b",
    className,
    children,
    disabled,
    imageLoadingStrategy = "lazy",
  } = props;
  let As: any = 'a';
  if (type == "submit" || type=="button") {
    As = "button";
    buttonProps.type = type;
  }
  
  if (type == "link" && !buttonProps.href) buttonProps.href = "#";
  if (type == "link" && !buttonProps.scroll) buttonProps.scroll = false;

  return (
    <As
      {...buttonProps}
      disabled={disabled}
      // href={button?.url || "/"}
      // target={button?.target}
      className={clsx(styles.root, className, {
        [styles.y]: color == "y",
        [styles.h]: color == "h", //when bg banner is white,button bg is yellow & hover is white
        [styles.b]: color == "b",
        [styles.w]: color == "w",
        [styles.o]: bg == "o",
        [styles.l]: size == "l",
      })}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <span className="uppercase font-raleway">{children}</span>

      {color == "y" ? (
        <SvgToUse type={"normal"} iconDirection={"right"} />
      ) : (
        icon && (
          <>
            <img
              src={hoverIcon || icon}
              alt="button-icon"
              loading={imageLoadingStrategy}
              fetchPriority="low"
              className={clsx({
                ["hidden"]: !isHover,
              })}
            />
            <img
              src={icon}
              alt="button-icon"
              loading={imageLoadingStrategy}
              fetchPriority="low"
              className={clsx({
                ["hidden"]: isHover,
              })}
            />
          </>
        )
      )}
    </As>
  );
};

export default Button;
