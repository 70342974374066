"use client";

import { useEffect, useState } from 'react';

export function useDeviceInfo() {
  const [deviceType, setDeviceType] = useState<string>('Unknown');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userAgent = window.navigator.userAgent;

      let detectedDeviceType: string = 'Unknown';
      switch (true) {
        case /Android/i.test(userAgent):
          detectedDeviceType = 'Android';
          break;
        case /iPhone|iPad|iPod/i.test(userAgent):
          detectedDeviceType = 'iOS';
          break;
        case /Windows Phone/i.test(userAgent):
          detectedDeviceType = 'Windows Phone';
          break;
        case /Macintosh|MacIntel|MacPPC|MacIntel/i.test(userAgent):
          detectedDeviceType = 'Mac';
          break;
        case /Windows|Win32|Win64|Windows NT/i.test(userAgent):
          detectedDeviceType = 'Windows';
          break;
        case /Linux/i.test(userAgent):
          detectedDeviceType = 'Linux';
          break;
        default:
          detectedDeviceType = 'Unknown';
          break;
      }

      setDeviceType(detectedDeviceType);
    }
  }, []);

  return deviceType;
}
