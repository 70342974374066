export const getGlobalStateValue = (value) => {
  if (typeof window !== "undefined") {
    // update latest pageType 
   const pageType = document.querySelector('meta[name="page-type"]')?.getAttribute("content") || "Home Page"
    updateStateSession({ pageType })

    const globalState = JSON.parse(sessionStorage.getItem("globalStore")) || {};

    return globalState[value];
  }
};

export const updateStateSession = (newState) => {
  if (typeof window !== "undefined") {
    const prevState = JSON.parse(sessionStorage.getItem("globalStore"));

    sessionStorage.setItem(
      "globalStore",
      JSON.stringify({ ...prevState, ...newState })
    );
  }
};

