import { IGAEvent } from "./gaEvents";

export const getGAPayload = (GAData: Partial<IGAEvent>, searchParams: Partial<URLSearchParams>, additionalData: Partial<any>): IGAEvent => {
  
  const utm: Partial<IGAEvent> = {
    utm_campaign: searchParams.get('utm_campaign') || "NA",
    utm_source: searchParams.get('utm_source') || "NA",
    utm_medium: searchParams.get('utm_medium') || "NA",
    utm_content: searchParams.get('utm_content') || "NA",
    utm_term: searchParams.get('utm_term') || "NA",
    utm_keyword: searchParams.get('utm_keyword') || "NA",
  };

  const otherData: Partial<IGAEvent> = {
    platform_os: additionalData?.platform || "NA",
    platform_version: additionalData?.version || "NA",
    device_type: additionalData?.deviceType || "NA",
    page_title: additionalData?.pageTitle || "NA",
    lead_identifier: additionalData?.pageReferrer || "NA",
    page_url : additionalData?.pageUrl || "NA",
  };
  
  const gaData: Partial<IGAEvent> = {
    ...GAData,
    enhanced_conversion : GAData?.enhanced_conversion || {},
    enhanced_conversion_data : GAData?.enhanced_conversion_data || {},
  }
  
  
  const payload: IGAEvent = {
    ...gaData,
    ...utm,
    ...otherData,
    event: GAData.event, // Ensure event is provided
    label: GAData?.label || "",
  };

  return payload;
};

